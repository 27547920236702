<template>
	<div v-if="this.unverified" class="login-register_area">
		<div class="container">
			<div class="row">
				<div
					class="col-md-11 col-lg-10 col-sm-12 col-xs-12 center-block"
					style="margin: auto"
				>
					<div class="login-form">
						<form-wizard
							:start-index="tab"
							ref="wizard"
							@on-complete="finishVerificationProcess"
							title="Verificación de Datos"
							subtitle="Gracias por completar los siguientes pasos para verificar tus datos de usuario"
							color="#003d74"
							error-color="#a94442"
						>
							<template slot="step" slot-scope="props">
								<wizard-step
									:tab="props.tab"
									:transition="props.transition"
									:key="props.tab.title"
									:index="props.index"
								></wizard-step>
							</template>
							<tab-content validateOnBack="false" title="Teléfono" icon>
								<div v-if="!change">
									<p class="text-center">
										Hola {{ userName }} {{ userLastname }}, por favor presiona el botón
										<strong>CONFIRMAR</strong> si tu número de teléfono es:
										<strong>+{{ userNumberPhone }}</strong>
										<br />Si deseas cambiar tu número de teléfono presiona el botón
										<strong>CAMBIAR NÚMERO</strong>
									</p>

									<div class="row">
										<div class="col-md-6">
											<button
												class="btn ht-btn--round"
												@click="change = true"
												style="float: right"
											>
												CAMBIAR NÚMERO
											</button>
										</div>
										<div class="col-md-6">
											<button class="btn ht-btn--round" @click="sendValidationCode()">
												CONFIRMAR
											</button>
										</div>
									</div>
								</div>

								<div v-if="change">
									<div
										class="col-md-10 col-lg-8 col-sm-12 col-xs-12 center-block"
										style="margin: auto"
									>
										<form action class="form" @submit.prevent="sendValidationCode()">
											<div class="row">
												<div class="mb--20" style="width: 50%">
													<label>Código de país *</label>
													<select v-model="countryCode" required class="form-control">
														<option value="376">AD - Andorra (+376)</option>
														<option value="971">AE - United Arab Emirates (+971)</option>
														<option value="93">AF - Afghanistan (+93)</option>
														<option value="1268">AG - Antigua And Barbuda (+1268)</option>
														<option value="1264">AI - Anguilla (+1264)</option>
														<option value="355">AL - Albania (+355)</option>
														<option value="374">AM - Armenia (+374)</option>
														<option value="599">AN - Netherlands Antilles (+599)</option>
														<option value="244">AO - Angola (+244)</option>
														<option value="672">AQ - Antarctica (+672)</option>
														<option value="54">AR - Argentina (+54)</option>
														<option value="1684">AS - American Samoa (+1684)</option>
														<option value="43">AT - Austria (+43)</option>
														<option value="61">AU - Australia (+61)</option>
														<option value="297">AW - Aruba (+297)</option>
														<option value="994">AZ - Azerbaijan (+994)</option>
														<option value="387">
															BA - Bosnia And Herzegovina (+387)
														</option>
														<option value="1246">BB - Barbados (+1246)</option>
														<option value="880">BD - Bangladesh (+880)</option>
														<option value="32">BE - Belgium (+32)</option>
														<option value="226">BF - Burkina Faso (+226)</option>
														<option value="359">BG - Bulgaria (+359)</option>
														<option value="973">BH - Bahrain (+973)</option>
														<option value="257">BI - Burundi (+257)</option>
														<option value="229">BJ - Benin (+229)</option>
														<option value="590">BL - Saint Barthelemy (+590)</option>
														<option value="1441">BM - Bermuda (+1441)</option>
														<option value="673">BN - Brunei Darussalam (+673)</option>
														<option value="591">BO - Bolivia (+591)</option>
														<option value="55">BR - Brazil (+55)</option>
														<option value="1242">BS - Bahamas (+1242)</option>
														<option value="975">BT - Bhutan (+975)</option>
														<option value="267">BW - Botswana (+267)</option>
														<option value="375">BY - Belarus (+375)</option>
														<option value="501">BZ - Belize (+501)</option>
														<option value="1">CA - Canada (+1)</option>
														<option value="61">CC - Cocos (keeling) Islands (+61)</option>
														<option value="243">
															CD - Congo, The Democratic Republic Of The (+243)
														</option>
														<option value="236">
															CF - Central African Republic (+236)
														</option>
														<option value="242">CG - Congo (+242)</option>
														<option value="41">CH - Switzerland (+41)</option>
														<option value="225">CI - Cote D Ivoire (+225)</option>
														<option value="682">CK - Cook Islands (+682)</option>
														<option value="56">CL - Chile (+56)</option>
														<option value="237">CM - Cameroon (+237)</option>
														<option value="86">CN - China (+86)</option>
														<option value="57">CO - Colombia (+57)</option>
														<option value="506">CR - Costa Rica (+506)</option>
														<option value="53">CU - Cuba (+53)</option>
														<option value="238">CV - Cape Verde (+238)</option>
														<option value="61">CX - Christmas Island (+61)</option>
														<option value="357">CY - Cyprus (+357)</option>
														<option value="420">CZ - Czech Republic (+420)</option>
														<option value="49">DE - Germany (+49)</option>
														<option value="253">DJ - Djibouti (+253)</option>
														<option value="45">DK - Denmark (+45)</option>
														<option value="1767">DM - Dominica (+1767)</option>
														<option value="1809">DO - Dominican Republic (+1809)</option>
														<option value="213">DZ - Algeria (+213)</option>
														<option value="593">EC - Ecuador (+593)</option>
														<option value="372">EE - Estonia (+372)</option>
														<option value="20">EG - Egypt (+20)</option>
														<option value="291">ER - Eritrea (+291)</option>
														<option value="34">ES - Spain (+34)</option>
														<option value="251">ET - Ethiopia (+251)</option>
														<option value="358">FI - Finland (+358)</option>
														<option value="679">FJ - Fiji (+679)</option>
														<option value="500">
															FK - Falkland Islands (malvinas) (+500)
														</option>
														<option value="691">
															FM - Micronesia, Federated States Of (+691)
														</option>
														<option value="298">FO - Faroe Islands (+298)</option>
														<option value="33">FR - France (+33)</option>
														<option value="241">GA - Gabon (+241)</option>
														<option value="44">GB - United Kingdom (+44)</option>
														<option value="1473">GD - Grenada (+1473)</option>
														<option value="995">GE - Georgia (+995)</option>
														<option value="233">GH - Ghana (+233)</option>
														<option value="350">GI - Gibraltar (+350)</option>
														<option value="299">GL - Greenland (+299)</option>
														<option value="220">GM - Gambia (+220)</option>
														<option value="224">GN - Guinea (+224)</option>
														<option value="240">GQ - Equatorial Guinea (+240)</option>
														<option value="30">GR - Greece (+30)</option>
														<option value="502">GT - Guatemala (+502)</option>
														<option value="1671">GU - Guam (+1671)</option>
														<option value="245">GW - Guinea-bissau (+245)</option>
														<option value="592">GY - Guyana (+592)</option>
														<option value="852">HK - Hong Kong (+852)</option>
														<option value="504">HN - Honduras (+504)</option>
														<option value="385">HR - Croatia (+385)</option>
														<option value="509">HT - Haiti (+509)</option>
														<option value="36">HU - Hungary (+36)</option>
														<option value="62">ID - Indonesia (+62)</option>
														<option value="353">IE - Ireland (+353)</option>
														<option value="972">IL - Israel (+972)</option>
														<option value="44">IM - Isle Of Man (+44)</option>
														<option value="91">IN - India (+91)</option>
														<option value="964">IQ - Iraq (+964)</option>
														<option value="98">
															IR - Iran, Islamic Republic Of (+98)
														</option>
														<option value="354">IS - Iceland (+354)</option>
														<option value="39">IT - Italy (+39)</option>
														<option value="1876">JM - Jamaica (+1876)</option>
														<option value="962">JO - Jordan (+962)</option>
														<option value="81">JP - Japan (+81)</option>
														<option value="254">KE - Kenya (+254)</option>
														<option value="996">KG - Kyrgyzstan (+996)</option>
														<option value="855">KH - Cambodia (+855)</option>
														<option value="686">KI - Kiribati (+686)</option>
														<option value="269">KM - Comoros (+269)</option>
														<option value="1869">
															KN - Saint Kitts And Nevis (+1869)
														</option>
														<option value="850">
															KP - Korea Democratic Peoples Republic Of (+850)
														</option>
														<option value="82">KR - Korea Republic Of (+82)</option>
														<option value="965">KW - Kuwait (+965)</option>
														<option value="1345">KY - Cayman Islands (+1345)</option>
														<option value="7">KZ - Kazakstan (+7)</option>
														<option value="856">
															LA - Lao Peoples Democratic Republic (+856)
														</option>
														<option value="961">LB - Lebanon (+961)</option>
														<option value="1758">LC - Saint Lucia (+1758)</option>
														<option value="423">LI - Liechtenstein (+423)</option>
														<option value="94">LK - Sri Lanka (+94)</option>
														<option value="231">LR - Liberia (+231)</option>
														<option value="266">LS - Lesotho (+266)</option>
														<option value="370">LT - Lithuania (+370)</option>
														<option value="352">LU - Luxembourg (+352)</option>
														<option value="371">LV - Latvia (+371)</option>
														<option value="218">
															LY - Libyan Arab Jamahiriya (+218)
														</option>
														<option value="212">MA - Morocco (+212)</option>
														<option value="377">MC - Monaco (+377)</option>
														<option value="373">MD - Moldova, Republic Of (+373)</option>
														<option value="382">ME - Montenegro (+382)</option>
														<option value="1599">MF - Saint Martin (+1599)</option>
														<option value="261">MG - Madagascar (+261)</option>
														<option value="692">MH - Marshall Islands (+692)</option>
														<option value="389">
															MK - Macedonia, The Former Yugoslav Republic Of (+389)
														</option>
														<option value="223">ML - Mali (+223)</option>
														<option value="95">MM - Myanmar (+95)</option>
														<option value="976">MN - Mongolia (+976)</option>
														<option value="853">MO - Macau (+853)</option>
														<option value="1670">
															MP - Northern Mariana Islands (+1670)
														</option>
														<option value="222">MR - Mauritania (+222)</option>
														<option value="1664">MS - Montserrat (+1664)</option>
														<option value="356">MT - Malta (+356)</option>
														<option value="230">MU - Mauritius (+230)</option>
														<option value="960">MV - Maldives (+960)</option>
														<option value="265">MW - Malawi (+265)</option>
														<option value="52">MX - Mexico (+52)</option>
														<option value="60">MY - Malaysia (+60)</option>
														<option value="258">MZ - Mozambique (+258)</option>
														<option value="264">NA - Namibia (+264)</option>
														<option value="687">NC - New Caledonia (+687)</option>
														<option value="227">NE - Niger (+227)</option>
														<option value="234">NG - Nigeria (+234)</option>
														<option value="505">NI - Nicaragua (+505)</option>
														<option value="31">NL - Netherlands (+31)</option>
														<option value="47">NO - Norway (+47)</option>
														<option value="977">NP - Nepal (+977)</option>
														<option value="674">NR - Nauru (+674)</option>
														<option value="683">NU - Niue (+683)</option>
														<option value="64">NZ - New Zealand (+64)</option>
														<option value="968">OM - Oman (+968)</option>
														<option value="507">PA - Panama (+507)</option>
														<option value="51">PE - Peru (+51)</option>
														<option value="689">PF - French Polynesia (+689)</option>
														<option value="675">PG - Papua New Guinea (+675)</option>
														<option value="63">PH - Philippines (+63)</option>
														<option value="92">PK - Pakistan (+92)</option>
														<option value="48">PL - Poland (+48)</option>
														<option value="508">
															PM - Saint Pierre And Miquelon (+508)
														</option>
														<option value="870">PN - Pitcairn (+870)</option>
														<option value="1">PR - Puerto Rico (+1)</option>
														<option value="351">PT - Portugal (+351)</option>
														<option value="680">PW - Palau (+680)</option>
														<option value="595">PY - Paraguay (+595)</option>
														<option value="974">QA - Qatar (+974)</option>
														<option value="40">RO - Romania (+40)</option>
														<option value="381">RS - Serbia (+381)</option>
														<option value="7">RU - Russian Federation (+7)</option>
														<option value="250">RW - Rwanda (+250)</option>
														<option value="966">SA - Saudi Arabia (+966)</option>
														<option value="677">SB - Solomon Islands (+677)</option>
														<option value="248">SC - Seychelles (+248)</option>
														<option value="249">SD - Sudan (+249)</option>
														<option value="46">SE - Sweden (+46)</option>
														<option value="65">SG - Singapore (+65)</option>
														<option value="290">SH - Saint Helena (+290)</option>
														<option value="386">SI - Slovenia (+386)</option>
														<option value="421">SK - Slovakia (+421)</option>
														<option value="232">SL - Sierra Leone (+232)</option>
														<option value="378">SM - San Marino (+378)</option>
														<option value="221">SN - Senegal (+221)</option>
														<option value="252">SO - Somalia (+252)</option>
														<option value="597">SR - Suriname (+597)</option>
														<option value="239">ST - Sao Tome And Principe (+239)</option>
														<option value="503">SV - El Salvador (+503)</option>
														<option value="963">SY - Syrian Arab Republic (+963)</option>
														<option value="268">SZ - Swaziland (+268)</option>
														<option value="1649">
															TC - Turks And Caicos Islands (+1649)
														</option>
														<option value="235">TD - Chad (+235)</option>
														<option value="228">TG - Togo (+228)</option>
														<option value="66">TH - Thailand (+66)</option>
														<option value="992">TJ - Tajikistan (+992)</option>
														<option value="690">TK - Tokelau (+690)</option>
														<option value="670">TL - Timor-leste (+670)</option>
														<option value="993">TM - Turkmenistan (+993)</option>
														<option value="216">TN - Tunisia (+216)</option>
														<option value="676">TO - Tonga (+676)</option>
														<option value="90">TR - Turkey (+90)</option>
														<option value="1868">TT - Trinidad And Tobago (+1868)</option>
														<option value="688">TV - Tuvalu (+688)</option>
														<option value="886">
															TW - Taiwan, Province Of China (+886)
														</option>
														<option value="255">
															TZ - Tanzania, United Republic Of (+255)
														</option>
														<option value="380">UA - Ukraine (+380)</option>
														<option value="256">UG - Uganda (+256)</option>
														<option value="1">US - United States (+1)</option>
														<option value="598">UY - Uruguay (+598)</option>
														<option value="998">UZ - Uzbekistan (+998)</option>
														<option value="39">
															VA - Holy See (vatican City State) (+39)
														</option>
														<option value="1784">
															VC - Saint Vincent And The Grenadines (+1784)
														</option>
														<option value="58" selected>VE - Venezuela (+58)</option>
														<option value="1284">
															VG - Virgin Islands, British (+1284)
														</option>
														<option value="1340">
															VI - Virgin Islands, U.s. (+1340)
														</option>
														<option value="84">VN - Viet Nam (+84)</option>
														<option value="678">VU - Vanuatu (+678)</option>
														<option value="681">WF - Wallis And Futuna (+681)</option>
														<option value="685">WS - Samoa (+685)</option>
														<option value="381">XK - Kosovo (+381)</option>
														<option value="967">YE - Yemen (+967)</option>
														<option value="262">YT - Mayotte (+262)</option>
														<option value="27">ZA - South Africa (+27)</option>
														<option value="260">ZM - Zambia (+260)</option>
														<option value="263">ZW - Zimbabwe (+263)</option>
													</select>
												</div>
												<div class="mb--20" style="width: 50%">
													<label>N° de telefono *</label>
													<input
														type="number"
														required
														style="padding: 6px; width: 100%"
														placeholder="4240000000"
														v-model="numberPhone"
													/>
												</div>
											</div>
											<div class="row">
												<button
													type="submit"
													style="margin: auto"
													class="btn ht-btn--round"
												>
													CONFIRMAR
												</button>
											</div>
										</form>
									</div>
								</div>
							</tab-content>

							<tab-content title="Ingresar código" icon>
								<p class="text-center">
									Te hemos enviado un SMS con el código de verificación de 4 dígitos a tu
									número telefónico.
								</p>
								<div class="mb--20" style="width: 50%; margin: auto">
									<form action class="form" @submit.prevent="confirmValidationCode()">
										<label class="text-center">Ingrese el código</label>
										<input
											id="inputCode"
											oninput="if(this.value.length > 4){this.value = this.value.slice(0,4);}"
											type="number"
											required
											min="1000"
											max="9999"
											style="
												padding: 6px;
												width: 20%;
												margin-left: 40%;
												text-align: center;
											"
											placeholder="0000"
											v-model="validationCode"
										/>
										<div class="row">
											<button
												type="submit"
												class="btn ht-btn--round"
												style="margin: auto"
											>
												Enviar
											</button>
										</div>
									</form>
								</div>
							</tab-content>
							<tab-content
								title="Documento de identidad (Frontal)"
								:before-change="setType()"
								icon
							>
								<p class="text-center">
									{{ userName }}, por tu seguridad, iniciaremos un proceso rutinario para
									verificar tu identidad. <br />El documento debe estar a tu nombre y la
									foto debe ser legible, sin sombras o brillos.
								</p>
								<!--<form action class="form" @submit.prevent="confirmValidationCode()">
                  <div class="row">
                    <div class="mb--20" style="width: 50%; margin:auto;">
                      <label class="text-center">Seleccione el tipo de documento</label>
                      <select
                        v-model="documentType"
                        style="width:200px; margin:auto;"
                        required
                        onchange="if (this.value == 'identification-card') {cedula = true }"
                        class="form-control"
                      >
                        <option value="identification-card">Cédula de identidad</option>
                        <option value="passport" selected>Pasaporte</option>
                      </select>
                    </div>
                  </div>
                </form>-->
								<vue-dropzone
									ref="dropzoneFront"
									id="dropzoneFront"
									v-on:vdropzone-error="catchDropzoneError"
									v-on:vdropzone-mounted="setUrlFront"
									v-on:vdropzone-success="completeUpload"
									v-on:vdropzone-sending="sendingEventFront"
									:options="dropzoneFrontOptions"
									:useCustomSlot="true"
								>
									<div class="dropzone-custom-content">
										<div class="subtitle">
											Arrastra y suelta, o haz clic para seleccionar una foto de tu
											dispositivo
										</div>
									</div>
								</vue-dropzone>
							</tab-content>
							<tab-content v-if="cedula" title="Documento de identidad (Reverso)" icon>
								<p class="text-center">
									Para finalizar el registro de tu documento de identidad, por favor
									agrega una foto del reverso de éste.
									<br />El documento debe estar a tu nombre y la foto debe ser legible,
									sin sombras o brillos.
								</p>
								<vue-dropzone
									ref="dropzoneBack"
									id="dropzoneBack"
									v-on:vdropzone-error="catchDropzoneError"
									:options="dropzoneBackOptions"
									:useCustomSlot="true"
									v-on:vdropzone-mounted="setUrlBack"
									v-on:vdropzone-sending="sendingEventBack"
									v-on:vdropzone-success="completeUpload"
								>
									<div class="dropzone-custom-content">
										<div class="subtitle">
											Arrastra y suelta, o haz clic para seleccionar una foto de tu
											dispositivo
										</div>
									</div>
								</vue-dropzone>
							</tab-content>
							<tab-content title="Opcional" style="padding-top: 5%" icon>
								<p class="text-center">
									Indica una red social propia que nos permita validar su identidad más
									rápidamente.
									<br />Esta información es opcional pero sugerimos su ingreso.
								</p>
								<div
									class="col-md-10 col-lg-8 col-sm-12 col-xs-12 center-block"
									style="margin: auto"
								>
									<form action class="form" @submit.prevent="setSocialNetwork()">
										<div class="row">
											<div class="mb--20" style="width: 40%">
												<label>Red Social *</label>
												<select v-model="socialNetwork" required class="form-control">
													<option value="facebook">Facebook</option>
													<option value="instagram">Instagram</option>
													<option value="twitter">Twitter</option>
													<option value="linkedin">LinkedIn</option>
												</select>
											</div>
											<div class="mb--20" style="width: 60%">
												<label>Usuario *</label>
												<input
													type="string"
													maxlength="100"
													required
													style="padding: 6px; width: 100%"
													v-model="socialNetworkUser"
												/>
											</div>
										</div>
										<div class="row">
											<div style="margin: auto">
												<button
													type="submit"
													style="margin: 5px"
													class="btn ht-btn--round"
												>
													REGISTRAR
												</button>
												<button
													@click.prevent="finish()"
													style="margin: 5px"
													class="btn ht-btn--round"
												>
													OMITIR
												</button>
											</div>
										</div>
									</form>
								</div>
							</tab-content>
							<tab-content title="Enviar datos" icon="fa fa-check">
								<h3 class="text-center">¡Proceso completado!</h3>
								<br />
								<p class="text-center">
									Con el envío de tu documento de identidad se iniciará un proceso
									rutinario de verificación, el cual nos permitirá tener una relación más
									segura. Cuando éste finalice satisfactoriamente, recibirás un correo que
									te permitirá seguir disfrutando de nuestros servicios y a nosotros nos
									permitirá seguir compartiendo contigo. En caso de que tengas alguna
									inquietud, por favor contáctanos a
									<strong>soporte@malllikeu.com</strong>. <br />Este proceso es único y
									puede demorar más de 24 horas.
									<br />
								</p>

								<h4 class="text-center">
									¡Recuerda, tu seguridad siempre es nuestra prioridad!
								</h4>
							</tab-content>
							<template slot="footer" slot-scope="props">
								<div class="wizard-footer-left">
									<wizard-button
										v-if="props.activeTabIndex > 10000"
										@click.native="props.prevTab()"
										:style="props.fillButtonStyle"
										>Previous</wizard-button
									>
								</div>
								<div class="wizard-footer-right">
									<wizard-button
										v-if="props.activeTabIndex > 10000"
										@click.native="props.nextTab()"
										class="wizard-footer-right"
										:style="props.fillButtonStyle"
										>Next</wizard-button
									>

									<wizard-button
										v-if="props.isLastStep"
										@click.native="finishVerificationProcess"
										class="wizard-footer-right finish-button"
										:style="props.fillButtonStyle"
										>{{ props.isLastStep ? 'Listo' : 'Next' }}</wizard-button
									>
								</div>
							</template>
						</form-wizard>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	import vue2Dropzone from 'vue2-dropzone'
	import 'vue2-dropzone/dist/vue2Dropzone.min.css'
	import { FormWizard, TabContent } from 'vue-form-wizard'
	import 'vue-form-wizard/dist/vue-form-wizard.min.css'
	export default {
		data: () => ({
			email: '',
			userNumberPhone: '',
			newUserNumberPhone: '',
			contador: 0,
			countryCode: 58,
			numberPhone: '',
			userName: '',
			userLastname: '',
			change: false,
			validationCode: '',
			identificationNumber: '',
			documentType: '',
			typeSelected: false,
			cedula: false,
			user: '',
			tab: 0,
			unverified: false,
			phoneVerified: '',
			verification: '',
			identificationSymbol: '',
			identificationType: '',
			socialNetwork: '',
			socialNetworkUser: '',
			dropzoneFrontOptions: {
				url: process.env.VUE_APP_AUTH_API + 'api/validate/photo',
				method: 'POST',
				resizeWidth: 500,
				timeout: 180000,
				resizeQuality: 0.8,
				//params: { back: false },
				thumbnailWidth: 200,
				addRemoveLinks: true,
				dictDefaultMessage: "<i class='fa fa-cloud-upload'></i>Click aquí",
			},
			dropzoneBackOptions: {
				url: process.env.VUE_APP_AUTH_API + 'api/validate/photo',
				method: 'POST',
				resizeWidth: 500,
				timeout: 180000,
				resizeQuality: 0.8,
				//params: { back: true },
				thumbnailWidth: 200,
				addRemoveLinks: true,
				dictDefaultMessage: "<i class='fa fa-cloud-upload'></i>Click aquí",
			},
		}),
		name: 'VerifyUserSection',
		components: {
			FormWizard,
			TabContent,
			vueDropzone: vue2Dropzone,
		},
		created() {
			this.email = this.$route.query.email
			this.getUser()
		},
		mounted() {
			this.user = JSON.parse(sessionStorage.getItem('user'))
			this.setType()
			this.changeDropzoneTab()
		},
		methods: {
			catchDropzoneError(file, message, xhr) {
				console.log(message)
				this.$swal({
					title: 'Ocurrió un error',
					icon: 'error',
					confirmButtonColor: '#e94e1b',
					showCancelButton: false,
					showConfirmButton: true,
				})
				this.$swal.showValidationMessage(`Solicitud rechazada: ${message.errors}`)
			},
			setSocialNetwork() {
				let urlSetSocialNetwork =
					process.env.VUE_APP_AUTH_API +
					'api/validate/' +
					this.email +
					'/social-network/set'
				axios
					.post(urlSetSocialNetwork, {
						socialnetwork: this.socialNetwork,
						socialnetworkuser: this.socialNetworkUser,
					})
					.then((response) => {
						//console.log(response.data);
					})
				this.$refs.wizard.nextTab()
			},
			finish() {
				this.$refs.wizard.nextTab()
			},
			setUrlFront() {
				this.$refs.dropzoneFront.setOption(
					'url',
					process.env.VUE_APP_AUTH_API + 'api/validate/' + this.email + '/photo'
				)
			},
			setUrlBack() {
				this.$refs.dropzoneBack.setOption(
					'url',
					process.env.VUE_APP_AUTH_API + 'api/validate/' + this.email + '/photo'
				)
			},
			finishVerificationProcess() {
				this.$router.go(-1)
			},
			completeUpload: function (file, response) {
				if (response.message == 'Successfully uploaded photo') {
					this.$refs.wizard.nextTab()
				}
			},
			changeDropzoneTab() {
				var tab = document.getElementsByClassName('dz-remove')
				tab.innerHTML = 'Eliminar foto'
			},
			sendingEventFront(file, xhr, formData) {
				formData.append('back', 0)
				formData.append('photo', file)
			},
			sendingEventBack(file, xhr, formData) {
				formData.append('back', 1)
				formData.append('photo', file)
			},
			setType() {
				switch (this.identificationType) {
					case 'identification-card':
						this.identificationSymbol = 'V'
						this.cedula = true
						break
					case 'v':
						this.identificationSymbol = 'V'
						this.cedula = true
						break
					case 'e':
						this.identificationSymbol = 'E'
						this.cedula = true
						break

					case 'j':
						this.cedula = false
						this.identificationSymbol = 'J'
						break

					case 'p':
						this.cedula = false
						this.identificationSymbol = 'P'
						break

					case 'g':
						this.cedula = false
						this.identificationSymbol = 'G'
						break

					default:
						this.cedula = false
						this.identificationSymbol = 'O'
						break
				}
			},
			confirmValidationCode() {
				console.log(this.validationCode)
				const urlConfirmValidationCode =
					process.env.VUE_APP_AUTH_API +
					'api/validate/' +
					this.email +
					'/code/' +
					this.validationCode
				axios
					.post(urlConfirmValidationCode, {
						headers: {
							Accept: 'application/json',
						},
					})
					.then((response) => {
						if (response.status == 200) {
							console.log(this.$refs.wizard)
							this.$refs.wizard.nextTab()
						} else {
							this.$swal({
								title: 'Código Inválido',
								text: 'Compruebe el código e intente nuevamente',
								timer: 4000,
								icon: 'info',
								confirmButtonColor: '#e94e1b',
								showCancelButton: false,
								showConfirmButton: false,
							})
							this.$swal.showLoading()
						}
					})
					.catch((error) => {
						this.$swal({
							title: 'Código Inválido',
							text: 'Compruebe el código e intente nuevamente',
							icon: 'error',
							showCancelButton: false,
							confirmButtonColor: '#e94e1b',
							showConfirmButton: true,
						})
						this.$swal.showValidationMessage(`Solicitud rechazada: ${error}`)
						console.log(error)
						//this.$router.push("/");
						this.error = true
					})
			},
			setFullPhone() {
				if (this.change) {
					this.newUserNumberPhone = this.countryCode + this.numberPhone
				} else {
					this.newUserNumberPhone = this.userNumberPhone
				}
				console.log(this.newUserNumberPhone)
			},
			sendValidationCode() {
				this.setFullPhone()
				const urlSendValidationCode =
					process.env.VUE_APP_AUTH_API +
					'api/validate/' +
					this.email +
					'/set-phone/' +
					this.newUserNumberPhone
				axios
					.post(urlSendValidationCode, {
						headers: {
							Accept: 'application/json',
						},
					})
					.then((response) => {
						console.log(response.data)
						console.log(this.$refs.wizard)
						this.$refs.wizard.nextTab()
					})
					.catch((error) => {
						this.$swal({
							title: 'Ocurrió un problema',
							text: 'Compruebe su conexion a internet e intente más tarde',
							icon: 'error',
							showCancelButton: false,
							confirmButtonColor: '#e94e1b',
							showConfirmButton: true,
						})
						this.$swal.showValidationMessage(`Solicitud rechazada: ${error}`)
						console.log(error)
						//this.$router.push("/");
						this.error = true
					})
			},
			/*async getCountryCodes() {
      const urlGetCountryCodes = "https://restcountries.eu/rest/v2/all";
      axios
        .get(urlGetCountryCodes, {
          headers: { "content-type": "application/json" }
        })
        .then(response => {
          const data = response.data;
          let codesData = "";
          var i;
          for (i = 0; i < data.length; i++) {
            codesData =
              codesData +
              "{name:'" +
              data[i].name.replace(/[^a-zA-Z ]/g, "") +
              "',id:'" +
              data[i].callingCodes.shift() +
              "'},";
          }
          //console.log(codesData);
        });
    },*/

			getUser() {
				const urlUserData =
					process.env.VUE_APP_AUTH_API + 'api/validate/' + this.email + '/data'
				axios
					.get(urlUserData, {
						headers: {
							Accept: 'application/json',
						},
					})
					.then((response) => {
						if (response.status == 200) {
							var user = JSON.stringify(response.data)
							sessionStorage.setItem('user', user)
							this.userName = response.data.name
							this.userLastname = response.data.lastname
							this.userNumberPhone = response.data.phone
							this.newUserNumberPhone = response.data.phone
							this.identificationNumber = response.data.identificationNumber
							this.phoneVerified = response.data.phoneVerified
							this.identificationType = response.data.identificationType
							this.verification = response.data.verification
							if (this.verification == 'unverified') {
								if (this.phoneVerified == true) {
									this.tab = 2
								}
								this.unverified = true
							} else if (this.verification == 'rejected') {
								if (this.phoneVerified == true) {
									this.tab = 2
								}
								this.unverified = true
							} else if (this.verification == 'to be checked') {
								this.$swal({
									imageUrl:
										'https://think.kera.org/wp-content/uploads/2018/11/hourglass-800x500.jpg',
									imageWidth: 400,
									imageHeight: 200,
									title: `¡Hola ` + this.userName + `!`,
									html: `Hemos recibido tu solicitud de verificación. <br>
                Te contactaremos cuando el proceso finalice satisfactoriamente.<br>
                Este proceso puede tardar más de 24 horas.`,
									showCancelButton: false,
									confirmButtonColor: '#e94e1b',
									showConfirmButton: true,
									confirmButtonText: 'Aceptar',
								}).then((result) => {
									if (result.isConfirmed) {
										this.$router.go(-1)
									}
								})
							} else if (this.verification == 'verified') {
								this.$swal({
									imageUrl:
										'https://d2mkz4zdclmlek.cloudfront.net/webp/articles/customer-success-story-1.webp',
									imageWidth: 400,
									imageHeight: 200,
									title: `¡Hola ` + this.userName + `!`,
									html: `Tus datos han sido verificados con éxito. <br>
                <strong>¡Tu seguridad siempre es nuestra prioridad!</strong>`,
									showCancelButton: false,
									showConfirmButton: true,
									confirmButtonColor: '#e94e1b',
									confirmButtonText: 'Aceptar',
								}).then((result) => {
									if (result.isConfirmed) {
										this.$router.go(-1)
									}
								})
							}
						} else {
							this.$swal({
								title: 'Parece que hay algún error...',
								text: 'El email que intentas verificar, no existe.',
								showCancelButton: false,
								showConfirmButton: false,
							})
							setTimeout(() => this.$router.go(-1), 5000)
							this.$swal.showLoading()
						}
					})
					.catch((error) => {
						this.$swal({
							title: 'El email inválido',
							icon: 'error',
							showCancelButton: false,
							showConfirmButton: false,
						})
						setTimeout(() => this.$router.go(-1), 4000)
						console.log(error)
						//this.$router.push("/");
						this.error = true
					})
			},
		},
	}
</script>

<style lang="scss">
	@import '@/assets/scss/main.scss';

	.ht-btn--round {
		padding: 10px;
		padding-top: 5px;
		padding-bottom: 5px;
		margin-top: 20px;
		font-size: 18px;
		background-color: #e94e1b !important;
	}

	pre {
		overflow: auto;
	}

	pre .string {
		color: #885800;
	}

	pre .number {
		color: blue;
	}

	pre .boolean {
		color: magenta;
	}

	pre .null {
		color: red;
	}

	pre .key {
		color: green;
	}

	.form-control {
		padding-right: 5% !important;
		width: 90%;
	}

	.vue-form-wizard .wizard-header {
		padding: 0px;
	}

	.vue-form-wizard .wizard-title {
		color: #252422;
		font-weight: 600;
		margin: 0;
		font-size: 25px;
		text-align: center;
	}

	.vue-form-wizard .category {
		font-size: 18px;
		font-weight: 400;
		color: #000000;
		margin: 10px;
		text-align: center;
	}

	.dropzone-custom-content {
		position: relative;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
	}

	.dropzone-custom-title {
		margin-top: 0;
		color: #000000;
	}

	.subtitle {
		color: #314b5f;
	}

	.dropzone.dz-clickable {
		cursor: pointer;
		background-color: #003d7457;
		font: message-box;
	}

	.vue-form-wizard .wizard-nav-pills a,
	.vue-form-wizard .wizard-nav-pills li {
		-webkit-box-flex: 1;
		-ms-flex: 1;
		flex: 1;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-ms-flex-positive: 1;
		flex-grow: 1;
		padding-bottom: 20px;
	}

	.vue-form-wizard.md .wizard-icon-circle {
		width: 70px;
		height: 70px;
		font-size: 24px;
		border-color: rgb(0, 61, 116);
	}
</style>
