<template>
  <div>
    <Header />

    <VerifyUserSection />

    <BrandCarousel />

    <Footer />

    <OffCanvasMobileMenu />

    <!-- back to top start -->
    <back-to-top class="scroll-top" bottom="60px">
      <i class="ion-android-arrow-up"></i>
    </back-to-top>
    <!-- back to top end -->
  </div>
</template>

<script>
import Header from "@/components/Header";
import VerifyUserSection from "../components/sections/VerifyUserSection";
import BrandCarousel from "../components/BrandCarousel";
import Footer from "../components/Footer";
import OffCanvasMobileMenu from "@/components/OffCanvasMobileMenu";
export default {
  name: "VerifyUser",
  components: {
    Header,
    VerifyUserSection,
    BrandCarousel,
    Footer,
    OffCanvasMobileMenu
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.ht-btn--round {
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 20px;
}
</style>